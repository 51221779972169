import { FunctionComponent, SVGProps } from 'react';

import { ReactComponent as EmptySectionSitting } from 'assets/images/icons/new/empty-section-sitting.svg';
import { ReactComponent as EmptySectionStanding } from 'assets/images/icons/new/empty-section-standing.svg';
import { ReactComponent as IconActivity } from 'assets/images/icons/new/activity.svg';
import { ReactComponent as IconAdd } from 'assets/images/icons/new/add.svg';
import { ReactComponent as IconBalloon } from 'assets/images/icons/new/balloon.svg';
import { ReactComponent as IconAlias } from 'assets/images/icons/new/alias.svg';
import { ReactComponent as IconAliases } from 'assets/images/icons/new/aliases.svg';
import { ReactComponent as IconArrowDown } from 'assets/images/icons/new/arrow-down.svg';
import { ReactComponent as IconArrowLeft } from 'assets/images/icons/new/arrow-left.svg';
import { ReactComponent as IconArrowRight } from 'assets/images/icons/new/arrow-right.svg';
import { ReactComponent as IconArrowUp } from 'assets/images/icons/new/arrow-up.svg';
import { ReactComponent as IconKpiArrowUp } from 'assets/images/icons/new/arrow-kpi-up.svg';
import { ReactComponent as IconKpiArrowDown } from 'assets/images/icons/new/arrow-kpi-down.svg';
import { ReactComponent as IconBedroom } from 'assets/images/icons/new/bedroom.svg';
import { ReactComponent as IconBoard } from 'assets/images/icons/new/board.svg';
import { ReactComponent as IconBriefcase } from 'assets/images/icons/new/briefcase.svg';
import { ReactComponent as IconBuilding } from 'assets/images/icons/new/building.svg';
import { ReactComponent as IconBuildingMedia } from 'assets/images/icons/new/building-media.svg';
import { ReactComponent as IconBusFront } from 'assets/images/icons/new/bus-front.svg';
import { ReactComponent as IconCheck } from 'assets/images/icons/new/check.svg';
import { ReactComponent as IconClear } from 'assets/images/icons/new/clear.svg';
import { ReactComponent as IconClose } from 'assets/images/icons/new/close.svg';
import { ReactComponent as IconCoffee } from 'assets/images/icons/new/coffee.svg';
import { ReactComponent as IconComment } from 'assets/images/icons/new/comment.svg';
import { ReactComponent as IconCompany } from 'assets/images/icons/new/company.svg';
import { ReactComponent as IconComparative } from 'assets/images/icons/new/comparative.svg';
import { ReactComponent as IconCompass } from 'assets/images/icons/new/compass.svg';
import { ReactComponent as IconCrossHair } from 'assets/images/icons/new/crosshair.svg';
import { ReactComponent as IconDelete } from 'assets/images/icons/new/delete.svg';
import { ReactComponent as IconDoubleDash } from 'assets/images/icons/new/double-dash.svg';
import { ReactComponent as IconDownload } from 'assets/images/icons/new/download.svg';
import { ReactComponent as IconEdit } from 'assets/images/icons/new/edit.svg';
import { ReactComponent as IconExclude } from 'assets/images/icons/new/exclude.svg';
import { ReactComponent as IconExplore } from 'assets/images/icons/new/explore.svg';
import { ReactComponent as IconFind } from 'assets/images/icons/new/find.svg';
import { ReactComponent as IconGrid } from 'assets/images/icons/new/grid.svg';
import { ReactComponent as IconGridLarge } from 'assets/images/icons/new/grid-large.svg';
import { ReactComponent as IconInfo } from 'assets/images/icons/new/info.svg';
import { ReactComponent as IconInvestor } from 'assets/images/icons/new/investor.svg';
import { ReactComponent as IconLeasing } from 'assets/images/icons/new/leasing.svg';
import { ReactComponent as IconList } from 'assets/images/icons/new/list.svg';
import { ReactComponent as IconMap } from 'assets/images/icons/new/map.svg';
import { ReactComponent as IconMapMarker } from 'assets/images/icons/new/map-marker.svg';
import { ReactComponent as IconMapPin } from 'assets/images/icons/new/map-pin.svg';
import { ReactComponent as IconMapPinDark } from 'assets/images/icons/new/map-pin-dark.svg';
import { ReactComponent as IconMerge } from 'assets/images/icons/new/merge.svg';
import { ReactComponent as IconMinus } from 'assets/images/icons/new/minus.svg';
import { ReactComponent as IconNearbyAmenities } from 'assets/images/icons/new/nearby-amenities.svg';
import { ReactComponent as IconOccupier } from 'assets/images/icons/new/occupier.svg';
import { ReactComponent as IconPerformance } from 'assets/images/icons/new/performance.svg';
import { ReactComponent as IconPrivacy } from 'assets/images/icons/new/privacy.svg';
import { ReactComponent as IconProfilePicture } from 'assets/images/icons/new/profile-picture.svg';
import { ReactComponent as IconRanking } from 'assets/images/icons/new/ranking.svg';
import { ReactComponent as IconRecreationParks } from 'assets/images/icons/new/recreation-parks.svg';
import { ReactComponent as IconRectangle } from 'assets/images/icons/new/rectangle.svg';
import { ReactComponent as IconSale } from 'assets/images/icons/new/sale.svg';
import { ReactComponent as IconSchools } from 'assets/images/icons/new/schools.svg';
import { ReactComponent as IconSend } from 'assets/images/icons/new/send.svg';
import { ReactComponent as IconShare } from 'assets/images/icons/new/share.svg';
import { ReactComponent as IconStack } from 'assets/images/icons/new/stack.svg';
import { ReactComponent as IconTemple } from 'assets/images/icons/new/temple.svg';
import { ReactComponent as IconTenantProspect } from 'assets/images/icons/new/tenant-prospect.svg';
import { ReactComponent as IconTheater } from 'assets/images/icons/new/theater.svg';
import { ReactComponent as IconTimes } from 'assets/images/icons/new/times.svg';
import { ReactComponent as IconTouring } from 'assets/images/icons/new/touring.svg';
import { ReactComponent as IconUpload } from 'assets/images/icons/new/upload.svg';
import { ReactComponent as IconWalkscoreBike } from 'assets/images/icons/new/walkscore-bike.svg';
import { ReactComponent as IconWalkscoreCopy } from 'assets/images/icons/new/walkscore-copy.svg';
import { ReactComponent as IconWalkscoreTransit } from 'assets/images/icons/new/walkscore-transit.svg';
import { ReactComponent as IconWalkscoreWalk } from 'assets/images/icons/new/walkscore-walk.svg';
import { ReactComponent as IconWarning } from 'assets/images/icons/new/warning.svg';
import { ReactComponent as IconWorld } from 'assets/images/icons/new/world.svg';
import { ReactComponent as IconSpaces } from 'assets/images/icons/new/spaces.svg';
import { ReactComponent as TourOverview } from 'assets/images/icons/new/tour-overview.svg';
import { ReactComponent as IconArrowForwardRight } from 'assets/images/icons/new/arrow-forward-right.svg';
import { ReactComponent as IconEarthMarker } from 'assets/images/icons/new/earth-marker.svg';
import { ReactComponent as IconMore } from 'assets/images/icons/new/more.svg';
import { ReactComponent as IconUser } from 'assets/images/icons/new/user.svg';
import { ReactComponent as IconStar } from 'assets/images/icons/new/star.svg';
import { ReactComponent as IconFile } from 'assets/images/icons/new/file.svg';
import { ReactComponent as IconAddCircle } from 'assets/images/icons/new/add-circle.svg';
import { ReactComponent as IconPlus } from 'assets/images/icons/plus.svg';
import { ReactComponent as IconLink } from 'assets/images/icons/link.svg';
import { ReactComponent as IconSearch } from 'assets/images/icons/search-grey-64.svg';
import { ReactComponent as IconNote } from 'assets/images/icons/note.svg';
import { ReactComponent as IconLeasingTrends } from 'assets/images/icons/new/leasing-trends.svg';
import { ReactComponent as IconAttributes } from 'assets/images/icons/new/attributes.svg';
import { ReactComponent as IconDarkerAttributes } from 'assets/images/icons/empty-section.svg';
import { ReactComponent as IconTertiaryPoint } from 'assets/images/icons/tertiary-point.svg';
import { ReactComponent as ArrowSplit } from 'assets/images/icons/arrow-split.svg';
import { ReactComponent as DownloadRounded } from 'assets/images/icons/downloadRounded.svg';

import CAFlag from 'assets/images/flags/ca.svg';
import DEFlag from 'assets/images/flags/de.svg';
import IEFlag from 'assets/images/flags/ie.svg';
import ILFlag from 'assets/images/flags/il.svg';
import KRFlag from 'assets/images/flags/kr.svg';
import MXFlag from 'assets/images/flags/mx.svg';
import PLFlag from 'assets/images/flags/pl.svg';
import ROFlag from 'assets/images/flags/ro.svg';
import UKFlag from 'assets/images/flags/uk.svg';
import USFlag from 'assets/images/flags/us.svg';

import GreaterCanada from 'assets/images/greaterMarkets/canada.svg';
import GreaterGermany from 'assets/images/greaterMarkets/germany.svg';
import Global from 'assets/images/greaterMarkets/global.svg';
import GreaterIreland from 'assets/images/greaterMarkets/ireland.svg';
import GreaterIsrael from 'assets/images/greaterMarkets/israel.svg';
import GreaterUK from 'assets/images/greaterMarkets/UK.svg';
import GreaterUS from 'assets/images/greaterMarkets/US.svg';
import GreaterPoland from 'assets/images/greaterMarkets/poland.svg';

export const ICONS: {
  [name: string]: FunctionComponent<SVGProps<SVGSVGElement>>;
} = {
  activity: IconActivity,
  add: IconAdd,
  addCircle: IconAddCircle,
  alias: IconAlias,
  aliases: IconAliases,
  arrowDown: IconArrowDown,
  arrowForwardRight: IconArrowForwardRight,
  arrowKpiDown: IconKpiArrowDown,
  arrowKpiUp: IconKpiArrowUp,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  arrowSplit: ArrowSplit,
  arrowUp: IconArrowUp,
  attributes: IconAttributes,
  balloon: IconBalloon,
  bedroom: IconBedroom,
  board: IconBoard,
  briefcase: IconBriefcase,
  building: IconBuilding,
  buildingMedia: IconBuildingMedia,
  busFront: IconBusFront,
  check: IconCheck,
  clear: IconClear,
  close: IconClose,
  coffee: IconCoffee,
  comment: IconComment,
  company: IconCompany,
  comparative: IconComparative,
  compass: IconCompass,
  crosshair: IconCrossHair,
  darkerAttributes: IconDarkerAttributes,
  delete: IconDelete,
  doubleDash: IconDoubleDash,
  download: IconDownload,
  downloadRounded: DownloadRounded,
  earthMarker: IconEarthMarker,
  edit: IconEdit,
  emptySectionSitting: EmptySectionSitting,
  emptySectionStanding: EmptySectionStanding,
  exclude: IconExclude,
  explore: IconExplore,
  file: IconFile,
  find: IconFind,
  grid: IconGrid,
  gridLarge: IconGridLarge,
  info: IconInfo,
  investor: IconInvestor,
  leasing: IconLeasing,
  leasingTrends: IconLeasingTrends,
  link: IconLink,
  list: IconList,
  map: IconMap,
  mapMarker: IconMapMarker,
  mapPin: IconMapPin,
  mapPinDark: IconMapPinDark,
  merge: IconMerge,
  minus: IconMinus,
  more: IconMore,
  nearbyAmenities: IconNearbyAmenities,
  note: IconNote,
  occupier: IconOccupier,
  performance: IconPerformance,
  plus: IconPlus,
  privacy: IconPrivacy,
  profilePicture: IconProfilePicture,
  ranking: IconRanking,
  recreationParks: IconRecreationParks,
  rectangle: IconRectangle,
  sale: IconSale,
  schools: IconSchools,
  search: IconSearch,
  send: IconSend,
  share: IconShare,
  spaces: IconSpaces,
  stack: IconStack,
  star: IconStar,
  temple: IconTemple,
  tenantProspect: IconTenantProspect,
  theater: IconTheater,
  tertiaryPoint: IconTertiaryPoint,
  times: IconTimes,
  touring: IconTouring,
  tourOverview: TourOverview,
  upload: IconUpload,
  user: IconUser,
  walkscoreBike: IconWalkscoreBike,
  walkscoreCopy: IconWalkscoreCopy,
  walkscoreTransit: IconWalkscoreTransit,
  walkscoreWalk: IconWalkscoreWalk,
  warning: IconWarning,
  world: IconWorld,
};

export const FLAGS_ICONS: { [name: string]: string } = {
  CA: CAFlag,
  DE: DEFlag,
  IE: IEFlag,
  IL: ILFlag,
  KR: KRFlag,
  MX: MXFlag,
  PL: PLFlag,
  RO: ROFlag,
  UK: UKFlag,
  US: USFlag,
};

export const GREATER_MARKET_IMAGES: { [key: string]: string } = {
  'Z - Greater US': GreaterUS,
  'Z - Greater Germany': GreaterGermany,
  'Z - Greater United Kingdom': GreaterUK,
  'Z - Greater Canada': GreaterCanada,
  'Z - Greater Israel': GreaterIsrael,
  'Z - Greater Ireland': GreaterIreland,
  'Z-Global': Global,
  'Z - Greater Poland': GreaterPoland,
};
