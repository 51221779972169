import { gql } from '@apollo/client';
import {
  PROPERTY_COMPLETE_DATA_FRAGMENT,
  PROPERTY_BASIC_DATA_FRAGMENT,
  PROPERTY_CLICKTHROUGHS_FRAGMENT,
} from 'graphql/property/fragments';
import { LEASE_OPTION_FRAGMENT } from './fragments';

export const SEARCH_LEASES_QUERY = gql`
  query leases($search: LeaseSearchInput!) {
    leases(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        id
        signDate
        type
        leaseType {
          id
          name
        }
        country {
          id
          name
          code
          flagURL
          distanceMeasurement
          areaMeasurement
          currencyCode
        }
        size
        sizeMt
        measurementSystem
        floor
        rentType
        escalation
        escalationType
        escalationFormatted
        escalationIncrement
        tenantImprovementAllowance
        termInMonths
        freeRentMonths
        baseRent
        netEffectiveRentCustom
        currencyCode
        expirationDate
        taxAndOpex
        tenantRepBrokers {
          id
          name
          company {
            id
            name
          }
        }
        transactionType
        tenantInMarketType
        tenantInMarketSubtype
        tenantInMarketSizeMin
        tenantInMarketSizeMax
        tenantInMarketSizeMaxMt
        tenantInMarketSizeMinMt
        tenantInMarketComments
        tenantCompany {
          id
          name
        }
        tenantBrokers {
          id
          name
          company {
            id
            name
          }
        }
        landlordBrokers {
          id
          name
          company {
            id
            name
          }
        }
        property {
          ...propertyCompleteDataFragment
        }
      }
    }
  }
  ${PROPERTY_COMPLETE_DATA_FRAGMENT}
`;

export const SEARCH_LEASES_BASIC_QUERY = gql`
  query leases($search: LeaseSearchInput!) {
    leases(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        id
        signDate
        type
        leaseType {
          id
          name
        }
        country {
          id
          name
          code
          flagURL
          distanceMeasurement
          areaMeasurement
          currencyCode
        }
        size
        sizeMt
        measurementSystem
        floor
        rentType
        escalation
        escalationType
        escalationFormatted
        escalationIncrement
        tenantImprovementAllowance
        termInMonths
        freeRentMonths
        baseRent
        netEffectiveRentCustom
        currencyCode
        expirationDate
        taxAndOpex
        tenantRepBrokers {
          id
          name
          company {
            id
            name
          }
        }
        tenantInMarketType
        tenantInMarketSubtype
        tenantInMarketSizeMin
        tenantInMarketSizeMax
        tenantInMarketSizeMaxMt
        tenantInMarketSizeMinMt
        tenantInMarketComments
        tenantCompany {
          id
          name
        }
        tenantBrokers {
          id
          name
          company {
            id
            name
          }
        }
        landlordBrokers {
          id
          name
          company {
            id
            name
          }
        }
        property {
          ...propertyBasicDataFragment
        }
      }
    }
  }
  ${PROPERTY_BASIC_DATA_FRAGMENT}
`;

export const SEARCH_LEASES_CLICKTHROUGHS_QUERY = gql`
  query leases($search: LeaseSearchInput!) {
    leases(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        baseRent
        country {
          id
          name
          code
          flagURL
          distanceMeasurement
          areaMeasurement
          currencyCode
        }
        currencyCode
        escalation
        escalationIncrement
        escalationFormatted
        escalationType
        expirationDate
        floor
        freeRentMonths
        id
        landlordBrokers {
          id
          name
          company {
            id
            name
          }
        }
        measurementSystem
        property {
          ...propertyClickthroughsFragment
        }
        rentType
        signDate
        size
        sizeMt
        tenantBrokers {
          id
          name
          company {
            id
            name
          }
        }
        tenantCompany {
          id
          name
        }
        tenantImprovementAllowance
        tenantRepBrokers {
          id
          name
          company {
            id
            name
          }
        }
        type
        transactionType
        leaseType {
          id
          name
        }
        termInMonths
        comments
      }
    }
  }
  ${PROPERTY_CLICKTHROUGHS_FRAGMENT}
`;

export const SEARCH_LEASES_FIND_COMPS_QUERY = gql`
  query leases($search: LeaseSearchInput!) {
    leases(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        id
        signDate
        type
        size
        sizeMt
        measurementSystem
        country {
          id
          name
          code
          flagURL
          distanceMeasurement
          areaMeasurement
          currencyCode
        }
        rentType
        escalation
        escalationIncrement
        escalationType
        tenantImprovementAllowance
        termInMonths
        freeRentMonths
        baseRent
        buildOutType {
          id
          name
        }
        currencyCode
        expirationDate
        taxAndOpex
        netEffectiveRent
        netEffectiveRentCustom
        netEffectiveRentMonthly
        timeMeasurement
        leaseType {
          id
          name
        }
        tenantCompany {
          id
          name
        }
        tenantBrokers {
          id
          name
          company {
            id
            name
          }
        }
        landlordBrokers {
          id
          name
          company {
            id
            name
          }
        }
        property {
          id
          primaryAddress
          displayAddress
          measurementSystem
        }
      }
    }
  }
`;

export const LEASES_MKT_RENT_AVG_QUERY = gql`
  query leasesMarketRentAverageNew($input: LeasesMarketRentAverageInput) {
    leasesMarketRentAverageNew(input: $input) {
      totalAverage
      averageType
      averages {
        date
        value
        valueMonthly
        NNNEquivValue
        NNNEquivValueMontly
        propertyValue
        totalSizeSF
        totalSizeSquareMeters
        measurementSystem
        leasesCount
        currencyCode
        topItems
      }
    }
  }
`;

export const LEASES_QUERY = gql`
  query leases($search: LeaseSearchInput!) {
    leases(search: $search) {
      results {
        id
        signDate
        baseRent
        rentType
        baseRentFullServiceEquiv
        baseRentNNNEquiv
        baseRentYard
        size
        sizeMt
        measurementSystem
        currencyCode
        tenantRepBrokers {
          id
          name
          company {
            id
            name
          }
        }
        tenantInMarketType
        tenantInMarketSubtype
        tenantInMarketSizeMin
        tenantInMarketSizeMax
        tenantInMarketSizeMaxMt
        tenantInMarketSizeMinMt
        tenantInMarketComments
      }
    }
  }
`;

export const GRAPH_LEASES_QUERY = gql`
  query graphLeases($input: GraphLeasesInput!) {
    graphLeases(input: $input) {
      rentType
      items {
        id
        value
        size
        sizeMt
        measurementSystem
        timeMeasurement
        baseRentFullServiceEquivMonthly
        baseRentFullServiceEquivAnnual
        baseRentNNNEquivAnnual
        baseRentNNNEquivMonthly
        date
        quarter
        year
        tenantCompany {
          id
          name
        }
        currencyCode
        rentType
        baseRent
      }
    }
  }
`;

export const SEARCH_LEASES_COMPANY_QUERY = gql`
  query leases($search: LeaseSearchInput!) {
    leases(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        id
        signDate
        type
        transactionType
        size
        sizeMt
        measurementSystem
        floor
        rentType
        escalation
        escalationFormatted
        tenantImprovementAllowance
        termInMonths
        freeRentMonths
        baseRent
        country {
          id
          name
          code
          flagURL
          distanceMeasurement
          areaMeasurement
          currencyCode
        }
        currencyCode
        expirationDate
        tenantRepBrokers {
          id
          name
          company {
            id
            name
          }
        }
        tenantInMarketType
        tenantInMarketSubtype
        tenantInMarketSizeMin
        tenantInMarketSizeMax
        tenantInMarketSizeMaxMt
        tenantInMarketSizeMinMt
        tenantInMarketComments
        tenantCompany {
          id
          name
        }
        property {
          id
          name
          primaryAddress
          displayAddress
          coverMediaUrl
          coverMediaType
          propertyCountry {
            id
            name
            code
            flagURL
            distanceMeasurement
            areaMeasurement
            currencyCode
          }
          latitude
          longitude
          latitudeDisplay
          longitudeDisplay
          status {
            id
            name
          }
        }
      }
    }
  }
`;

export const LEASES_EXPIRATION_SIZED_QUERY = gql`
  query leasesExpirationSized($search: LeasesExpirationSizedInput) {
    leasesExpirationSized(search: $search) {
      propertyLeasesExpirationSizedResponse {
        propertyId
        expirationSizedResponse {
          text
          value
          valueMt
          count
          measurementSystem
        }
      }
      aggregateLeasesExpirationSizedResponse {
        text
        value
        valueMt
        count
        measurementSystem
      }
    }
  }
`;

export const LEASES_CAROUSEL_QUERY = gql`
  query leasesCarousel($input: LeasesCarouselInput!) {
    leasesCarousel(input: $input) {
      items {
        id
        additionalConcessions
        additionalConcessionsPSF
        additionalConcessionsPSM
        commencementDate
        comments
        signDate
        expandingContracting {
          id
          name
        }
        type
        transactionType
        size
        sizeMt
        measurementSystem
        floor
        rentType
        freeRentType {
          id
          name
        }
        leaseOptions {
          ...leaseOption
        }
        escalation
        escalationFormatted
        tenantImprovementAllowance
        termInMonths
        freeRentMonths
        baseRent
        currencyCode
        expirationDate
        taxAndOpex
        tenantInMarketType
        tenantInMarketSubtype
        tenantInMarketSizeMin
        tenantInMarketSizeMax
        tenantInMarketSizeMaxMt
        tenantInMarketSizeMinMt
        tenantInMarketComments
        tenantInMarketLastDiscussedDate
        type
        tenantRepBroker {
          id
          name
          company {
            id
            name
          }
        }
        tenantCompany {
          id
          name
        }
        tenantBroker {
          id
          name
          company {
            id
            name
          }
        }
        landlordBroker {
          id
          name
          company {
            id
            name
          }
        }
        property {
          id
          name
          primaryAddress
          secondaryAddress
          tertiaryAddress
          quaternaryAddress
          numberOfAlternativeAddresses
          displayAddress
          coverMediaUrl
          coverMediaType
          latitude
          longitude
          latitudeDisplay
          longitudeDisplay
          propertyClass {
            id
            name
          }
          market {
            id
            name
          }
          submarket {
            id
            name
          }
          propertyType {
            id
            name
          }
        }
        createdAt
        createdUser {
          firstName
          lastName
          email
        }
        updatedAt
        updatedUser {
          firstName
          lastName
          email
        }
      }
    }
  }
  ${LEASE_OPTION_FRAGMENT}
`;

export const GRAPH_OWNER_PORTFOLIO_LEASES_QUERY = gql`
  query graphOwnerPortfolioLeases($input: GraphOwnerPortfolioInput!) {
    graphOwnerPortfolioLeases(input: $input) {
      totalSize
      totalSizeMt
      leases {
        id
        size
        sizeMt
        measurementSystem
        propertyId
        property {
          name
          status
          propertyClass
          propertyType
          market
          marketId
          submarketId
          submarket
          micromarket
          primaryAddress
          coverMediaUrl
          latitude
          longitude
        }
      }
    }
  }
`;

export const GET_LEASE_QUERY = gql`
  query getLease($id: ID!) {
    lease(id: $id) {
      id
      leaseType {
        id
        name
      }
      expandingContracting {
        id
        name
      }
      type
      signDate
      size
      sizeMt
      warehouseSf
      warehouseMt
      officeSize
      officeSizeMt
      driveInDoors
      loadingDocks
      leaseOptions {
        ...leaseOption
      }
      parkingSpaces
      trailerParkingSpaces
      priorAddress {
        id
        name
        primaryAddress
      }
      priorSize
      measurementSystem
      baseRent
      baseRentFullServiceEquiv
      baseRentFullServiceEquivAnnual
      baseRentFullServiceEquivMonthly
      baseRentNNNEquivAnnual
      baseRentNNNEquivMonthly
      baseRentPerKw
      buildOutType {
        id
        name
      }
      rentType
      rentPaymentDetail
      escalation
      escalationIncrement
      escalationIncrementType {
        id
        name
      }
      escalationFormatted
      escalationType
      escalationCap
      escalationCollar
      upwardOnly
      multipleBasis
      freeRentMonths
      freeRentType {
        id
        name
      }
      expirationDate
      currencyCode
      confidential
      financialStatus
      tenantImprovementAllowance
      cappedServiceCharges
      commencementDate
      termInMonths
      transactionType
      floor
      suite
      timeMeasurement
      taxAndOpex
      tax
      opex
      netEffectiveRentCustom
      netEffectiveRent
      netEffectiveRentMonthly
      additionalConcessions
      additionalConcessionsPSF
      additionalConcessionsPSM
      comments
      cleaningIncluded
      createdAt
      updatedAt
      createdUser {
        id
        firstName
        lastName
        email
      }
      updatedUser {
        id
        firstName
        lastName
        email
      }
      tenantRepBrokers {
        id
        name
        company {
          id
          name
        }
      }
      tenantInMarketId
      tenantInMarketType
      tenantInMarketSubtype
      tenantInMarketSizeMin
      tenantInMarketSizeMax
      tenantInMarketSizeMinMt
      tenantInMarketSizeMaxMt
      tenantInMarketComments
      tenantInMarketLastDiscussedDate
      tenantCompany {
        id
        name
      }
      tenantBrokers {
        id
        name
        company {
          id
          name
        }
      }
      landlordBrokers {
        id
        name
        company {
          id
          name
        }
      }
      spaceUse {
        id
        name
      }
      property {
        id
        name
        primaryAddress
        displayAddress
        type
        propertyCountry {
          id
          name
          code
          flagURL
          distanceMeasurement
          areaMeasurement
          currencyCode
        }
        latitude
        longitude
        latitudeDisplay
        longitudeDisplay
        market {
          id
          name
        }
        submarket {
          id
          name
        }
        micromarket {
          id
          name
        }
        propertyType {
          id
          name
        }
        propertyClass {
          id
          name
        }
        coverMediaUrl
        coverMediaType
      }
      sublessor {
        id
        name
      }
      securityDeposit
      securityDepositType {
        id
        name
      }
    }
  }
  ${LEASE_OPTION_FRAGMENT}
`;

export const LEASES_ANALYTICS_METADATA = gql`
  query leasesAnalyticsMetadata($search: LeasesAnalyticsMetadataInput) {
    leasesAnalyticsMetadata(search: $search) {
      averageRentFS
      averageRentFSMonthly
      averageRentNNN
      averageRentNNNMonthly
      averageFreeRent
      averageTerm
      averageTIPsf
      numberLeases
      sumLeaseSize
      sumLeaseSizeMt
      averageLeaseSize
      averageLeaseSizeMt
      totalTenantInMarket
      averageRentAtExpiration
      averageRentFSAtExpiration
      averageRentFSAtExpirationMonthly
      averageRentNNNAtExpiration
      averageRentNNNAtExpirationMonthly
      averageNetEffectiveRentFS
      averageNetEffectiveRentNNN
    }
  }
`;

export const LEASES_ANALYTICS_CONCESSION_QUERY = gql`
  query leasesAnalyticsConcession($search: LeasesAnalyticsConcessionInput) {
    leasesAnalyticsConcession(search: $search) {
      slices {
        year
        avgFreeRentMonth
        avgTIAllowance
        quarter
        topItems
        count
      }
    }
  }
`;

export const GET_NER_QUERY = gql`
  query getNetEffectiveRent($input: NetEffectiveRentInput!) {
    getNetEffectiveRent(input: $input)
  }
`;

export const LEASES_MOST_COMMON = gql`
  query leasesMostCommon($search: LeasesMostCommonInput!) {
    leasesMostCommon(search: $search) {
      rentType
      timeMeasurement
      valueFieldName
    }
  }
`;

export const LEASES_LEASING_TRENDS = gql`
  query leases($search: LeaseSearchInput!) {
    leases(search: $search) {
      total
      results {
        id
        property {
          id
          name
          propertyCountry {
            id
            name
            code
            flagURL
            distanceMeasurement
            areaMeasurement
            currencyCode
          }
          coverMediaType
          coverMediaUrl
          displayAddress
          primaryAddress
          currencyCode
          measurementSystem
          latitude
          longitude
          latitudeDisplay
          longitudeDisplay
          locationType {
            id
            name
          }
          market {
            id
            name
          }
          submarket {
            id
            name
          }
          micromarket {
            id
            name
          }
          amenities {
            id
            name
          }
          owners {
            id
            name
            website
            logoUrl
          }
          propertyClass {
            id
            name
          }
          propertyType {
            id
            name
          }
          propertySubtype {
            id
            name
          }
          renovatedYears {
            id
            year
          }
          builtYear
          buildingSize
          buildingSizeMt
          floorPlateSize
          floorPlateSizeMt
          parkingRatio
          propertyType {
            id
            name
          }
          dataCenter {
            id
            commissionedPower
            underConstPower
            plannedPower
          }
          multifamily {
            id
            totalUnits
            avgUnitSizeMt
            avgUnitSizeSf
          }
          siteCoverageRatio
          industrial {
            id
            driveInDoors
            loadingDocks
            typicalClearHeightMin
            typicalClearHeightMinMt
            typicalClearHeightMax
            typicalClearHeightMaxMt
            sprinklers
            trailerParkingSpaces
          }
          office {
            columnSpacingMax
            columnSpacingMaxMt
            columnSpacingMin
            columnSpacingMinMt
            typicalCeilingHeightFeet
            typicalCeilingHeightInches
            typicalCeilingHeightMt
          }
        }
        tenantCompany {
          id
          name
        }
        size
        signDate
        type
        termInMonths
        expirationDate
        leaseType {
          id
          name
        }
        measurementSystem
        floor
        rentType
        escalation
        escalationIncrement
        escalationType
        escalationFormatted
        tenantInMarketType
        tenantInMarketSubtype
        tenantInMarketSizeMin
        tenantInMarketSizeMax
        tenantInMarketSizeMaxMt
        tenantInMarketSizeMinMt
        tenantImprovementAllowance
        freeRentMonths
        baseRent
        baseRentNNNEquiv
        baseRentNNNEquivMonthly
        baseRentFullServiceEquiv
        baseRentFullServiceEquivMonthly
        currencyCode
        taxAndOpex
        netEffectiveRent
        netEffectiveRentNNN
        netEffectiveRentCustom
        tenantRepBrokers {
          id
          name
          company {
            id
            name
          }
        }
        tenantBrokers {
          id
          name
          company {
            id
            name
          }
        }
        landlordBrokers {
          id
          name
          company {
            id
            name
          }
        }
      }
    }
  }
`;

export const LEASE_TERMS_AVERAGES = gql`
  query leasesAnalyticsMostRecentLeases(
    $search: LeasesAnalyticsMostRecentInput
  ) {
    leasesAnalyticsMostRecentLeases(search: $search) {
      historicalAverage {
        averages {
          date
          totalSizeSF
          totalSizeSquareMeters
          leasesCount
          currencyCode
          measurementSystem
          topItems
          avgBaseRentFullServiceEquiv
          avgBaseRentFullServiceEquivMonthly
          avgBaseRentNNNEquiv
          avgBaseRentNNNEquivMonthly
          avgFreeRent
          avgTIPsf
          avgNetEffectiveRentFullService
          avgNetEffectiveNNN
          avgNetEffectiveRentFullServiceMonthly
          avgNetEffectiveNNNMonthly
        }
      }
    }
  }
`;

export const LEASE_OPTION_TYPES = gql`
  query leaseOptionTypes {
    leaseOptionTypes {
      id
      name
    }
  }
`;

export const LEASES_ACTIVITY = gql`
  query leasesActivity($input: LeasesActivityInput!) {
    leasesActivity(input: $input) {
      averageLeaseSize
      leaseCount
      aggregates {
        propertyId
        aggregateLeaseSize
        leaseCount
      }
    }
  }
`;
