import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  MouseEvent,
} from 'react';
import { ICONS } from 'assets';

interface Props {
  className?: string;
  color?: string;
  height?: number;
  name: string;
  size?: number;
  width?: number;
  onClick?: (event: MouseEvent) => void;
}

const DEFAULT_COLOR = '#ffffff';

const Icon: React.FC<Props> = ({
  className,
  color,
  height,
  name,
  size,
  width,
  onClick,
}) => {
  const IconComponent = ICONS[name];
  const ref = useRef<SVGSVGElement>(null);
  const iconSize = size || 1;
  const [defaultColor, setDefaultColor] = useState(color || DEFAULT_COLOR);

  const style = {
    width: `${width || iconSize}rem`,
    height: `${height || iconSize}rem`,
  };

  useEffect(() => {
    setDefaultColor(DEFAULT_COLOR);
  }, [name]);

  useLayoutEffect(() => {
    if (color && ref.current) {
      let svgContent = ref.current.innerHTML;
      svgContent = svgContent.replace(/fill="[^"]+"/g, `fill="${color}"`);
      svgContent = svgContent.replace(/stroke="[^"]+"/g, `stroke="${color}"`);

      if (svgContent !== ref.current.innerHTML) {
        ref.current.innerHTML = svgContent;
        setDefaultColor(color);
      }
    }
  }, [color, defaultColor]);

  return (
    <IconComponent
      onClick={onClick}
      className={className}
      style={style}
      ref={ref}
    />
  );
};

export default Icon;
